import { Box, Grid, Typography, Fade } from "@mui/material";
import Image from "../mainPhoto.jpg";
import "../mainPhoto.jpg";

const HomePageFragment = () => {
 
    return(
        <Box sx={{
            display:"flex",
            width:"100vw",
            backgroundColor:"#282828",
            height:"100vh",
            justifyContent:"center",
            alignItems:"center",
            textAlign:"center",
        }}>
            <Box sx = {{
                display:"flex",
                width:"100vw",
                height:"100vh",
                justifyContent:"center",
                textAlign:"center",
                flexDirection:"column",
                backgroundImage:`url(${Image})`,
                backgroundRepeat:"no-repeat",
                backgroundPosition:"center",
                backgroundSize: "100%, contain, cover"
            }}>
                <Typography 
                    variant="h3" 
                    sx={{
                        color:"white",
                        fontFamily:"CormorantBold",
                }}>
                    Kancelaria notarialna
                </Typography>
                <Typography 
                    variant="h3" 
                    sx={{
                        color:"white",
                        fontFamily:"CormorantBold",
                }}>
                    Marta Wieczorkowska-Kowalik
                </Typography>
                <Typography 
                    variant="h3" 
                    sx={{
                        color:"white",
                        fontFamily:"CormorantBold",
                }}>
                    Tomasz Starosta
                </Typography>
                <Typography 
                    variant="h5" 
                    sx={{
                        color:"white",
                        fontFamily:"CormorantBold",
                }}>
                    Bydgoszcz, ul. Długa 47 (Parter)
                </Typography>                                                                           
            </Box>
        </Box>
    );

}

export default HomePageFragment;
