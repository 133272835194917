import { Box, Grid, Typography, Fade } from "@mui/material";

import IconTile from "./IconTile";
import DraftsSharpIcon from '@mui/icons-material/DraftsSharp';
import MapSharpIcon from '@mui/icons-material/MapSharp';
import WatchLaterSharpIcon from '@mui/icons-material/WatchLaterSharp';

const InfoFragment = () => {

    const infoArray = [
        { TileColor : "#E7C689" , MiniBoxColor : "#282828", IconColor : "#D9D9D9" , Icon : <DraftsSharpIcon/>, Text : "Sekretariat\r\n- tel: 792-47-47-45\r\nNot. Marta Wieczorkowska-Kowalik\r\n- tel: 792-930-119\r\nNot. Tomasz Starosta\r\n- tel: 792-223-930\r\nAdres mail:\r\nnotariusz@dluga47.pl", HeightPercent : "70%", IsButton: false},
        { TileColor : "#E7C689" , MiniBoxColor : "#282828", IconColor : "#D9D9D9" , Icon : <MapSharpIcon/>, Text : "Kancelaria Notarialna\r\n Marta Wieczorkowska- Kowalik,\r\nTomasz Starosta.\r\nSpółka cywilna z siedzibą w Bydgoszczy,\r\nul. Długa 47 (parter)", HeightPercent : "70%", IsButton: true},
        { TileColor : "#E7C689" , MiniBoxColor : "#282828", IconColor : "#D9D9D9" , Icon : <WatchLaterSharpIcon/>, Text : "Pn. - Pt.: 9-17.\r\nPo wcześniejszym uzgodnieniu z notariuszem, istnieje możliwość dokonania czynności notarialnej w innym terminie\r\n(również w weekendy).", HeightPercent : "65%", IsButton: false},

    ];

    return(
        <Box sx={{
            display:"flex",
            width:"100vw",
            height:"auto",
            backgroundColor:"#282828",
            flexDirection:"column",
        }}>
            <Box sx={{
                display:"flex",
                width:"100vw",
                height:"20vh",
                marginTop:"3vh",
            }}>
                <Typography variant="h2" sx={{
                    fontFamily:"CormorantBold",
                    marginTop: "5vh",
                    color:'#D9D9D9',
                    marginLeft:"3vw",
                    textAlign:"start",
                }}>
                    Informacje
                </Typography>
            </Box>
            <Box sx ={{
                display:"flex",
                width:"100vw",
                height:"au",
                justifyContent:"center",
                alignItems:"center",
                flexWrap:"wrap",
            }}>
                {infoArray.map((infoRow) => (
                    <IconTile
                        TileColor={infoRow.TileColor}
                        MiniBoxColor={infoRow.MiniBoxColor}
                        IconColor={infoRow.IconColor}
                        Icon={infoRow.Icon}
                        Text={infoRow.Text}
                        HeightPercent={infoRow.HeightPercent}
                        IsButton={infoRow.IsButton}
                    />
                ))}
            </Box> 
            <Typography variant="h5" sx={{
                    fontFamily:"CormorantBold",
                    marginTop: "5vh",
                    color:'#D9D9D9',
                    textAlign:"center",
                    marginLeft: "5vw",
                    marginRight:"5vw",
            }}>
                Marta Wieczorkowska-Kowalik, Tomasz Starosta © 2023.
            </Typography>    
            <Typography variant="h6" sx={{
                    fontFamily:"CormorantBold",
                    marginTop: "1vh",
                    color:'#D9D9D9',
                    textAlign:"center",
                    marginBottom:"5vh",
            }}>
                Wszelkie prawa zastrzeżone.
            </Typography>               
        </Box>
    );
}

export default InfoFragment;