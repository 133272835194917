import { Box, Grid, Typography } from "@mui/material";
import CostsImage from "../costsPhoto.png";

const CostsFragment = () => {

    return(
        <Box sx={{
            display:"flex",
            width:"100vw",
            backgroundColor:"#D9D9D9",
            height:"78vh",
            justifyContent:"center",
            alignItems:"center",
            textAlign:{xs: "center", lg:"left"},
        }}>
            <Grid container spacing = {{xs: 0}} columns={{ xs: 2, lg: 4}}>
                <Grid item xs={2} sx={{
                    display : {xs : "none" , lg:"block"}, 
                    overflow:"hidden",
                }}>
                    <Box sx={{
                        display:"flex",
                        overflow:"hidden",
                        height:"90vh",
                        width:"50vw",
                        backgroundImage:`url(${CostsImage})`,
                        backgroundRepeat:"no-repeat",
                        backgroundPosition:"center",
                        backgroundSize: "100%, contain, cover"
                    }}>
                    </Box>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Typography variant="h2" sx={{
                        fontFamily:"CormorantBold",
                        marginTop: "2vh",
                        marginLeft:"3vw",
                        textAlign:"start",
                    }}>
                        Koszty
                    </Typography>
                    <Typography variant ="h4" sx={{
                        fontFamily:"Cormorant",
                        textAlign:"center",
                        marginTop: "15vh",
                        marginLeft:"2vw",
                        marginRight:"2vw"
                    }}>
                        Notariusz, za wykonywane czynności pobiera wynagrodzenie, którego stawka określona jest w
                        Rozporządzeniu Ministra Sprawiedliwości z dnia 28 czerwca 2004 roku 
                        w sprawie maksymalnych
                        stawek taksy notarialnej. Ponadto notariusz, przy dokonywanej czynności, pobiera 
                        należne podatki i
                        opłaty sądowe, których wysokość zależy od przedmiotu, rodzaju i wartości umowy.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );

}

export default CostsFragment;