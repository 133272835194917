import CostsFragment from "./mainFragments/CostsFragment";
import HomePageFragment from "./mainFragments/HomePageFragment";
import LawServicesFragment from "./mainFragments/LawServicesFragment";
import InfoFragment from "./mainFragments/InfoFragment";

import { Box, Fab, Fade, Grid, MenuItem, MenuList, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WorkSharpIcon from '@mui/icons-material/WorkSharp';
import LiveHelpSharpIcon from '@mui/icons-material/LiveHelpSharp';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';

import { createTheme, responsiveFontSizes, ThemeProvider, styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from "react";

const MainPage = () => {

    const StyledTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#282828",
            color: "#E7C689",
            fontFamily:"CormorantBold",
            fontSize: 14,
          },
        }));

    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    const HomeRef = useRef(null);
    const CostsRef = useRef(null);
    const LawRef = useRef(null);
    const InfoRef = useRef(null);

    const scrollToHome = () => { HomeRef.current.scrollIntoView({behavior:'smooth'}) }
    const scrollToCosts = () => { CostsRef.current.scrollIntoView({behavior:'smooth'}) }
    const scrollToLaw = () => { LawRef.current.scrollIntoView({behavior:'smooth'}) }
    const scrollToInfo = () => { InfoRef.current.scrollIntoView({behavior:'smooth'}) }

    return(
        <div>
            <ThemeProvider theme={theme}>
                <Box style={{
                    position:"fixed",
                    left: "0",
                    right: "0",
                    margin:"auto",
                    justifyContent:"center",
                    alignItems:"center",
                    width: "100vw",
                    height:"10vh",
                    zIndex:"5",
                    backgroundColor:"rgba(0,0,0,0)",
                }}>
                    <MenuList sx={{
                        flexDirection:"row",
                        display:"flex",
                        width:"100vw",
                        height:"10vh",
                        justifyContent:"center",
                        backgroundColor:"#282828"
                    }}>
                        <StyledTooltip title="Dom">
                            <MenuItem 
                                disableRipple 
                                onClick = {scrollToHome}
                                sx={{
                                    justifyContent: "center",
                                    textAlign: "center",
                                    alignItems: "center",
                                    flexDirection:"column",
                                    '&:hover':{
                                        opacity: "0.9",
                                        backgroundColor:"rgba(0,0,0,0)",
                                    
                                    
                            }}}>
                                <HomeIcon sx={{
                                    color: "#E7C689",
                                    scale: "1.5",
                                    marginLeft:"5vw",
                                    marginRight:"5vw"
                                }}/>
                            </MenuItem>
                        </StyledTooltip>
                        <StyledTooltip title="Czynności notarialne">
                            <MenuItem 
                                disableRipple 
                                onClick={scrollToLaw}
                                sx={{
                                    justifyContent: "center",
                                    textAlign: "center",
                                    alignItems: "center",
                                    '&:hover':{
                                        opacity: "0.9",
                                        backgroundColor:"rgba(0,0,0,0)",
                            }}}>
                                <AccountBalanceIcon sx={{
                                    color: "#E7C689",
                                    scale: "1.5",
                                    marginLeft:"5vw",
                                    marginRight:"5vw"
                                }}/>
                            </MenuItem>
                        </StyledTooltip>
                        <StyledTooltip title="Koszty">
                            <MenuItem 
                                disableRipple 
                                onClick={scrollToCosts}
                                sx={{
                                    justifyContent: "center",
                                    textAlign: "center",
                                    alignItems: "center",
                                    '&:hover':{
                                        opacity: "0.9",
                                        backgroundColor:"rgba(0,0,0,0)",
                            }}}>
                                <WorkSharpIcon sx={{
                                    color: "#E7C689",
                                    scale: "1.5",
                                    marginLeft:"5vw",
                                    marginRight:"5vw"
                                }}/>
                            </MenuItem>
                        </StyledTooltip>
                        <StyledTooltip title="Informacje">
                            <MenuItem 
                                disableRipple
                                onClick={scrollToInfo} 
                                sx={{
                                    justifyContent: "center",
                                    textAlign: "center",
                                    alignItems: "center",
                                    '&:hover':{
                                        opacity: "0.9",
                                        backgroundColor:"rgba(0,0,0,0)",
                            }}}>
                                <LiveHelpSharpIcon sx={{
                                    color: "#E7C689",
                                    scale: "1.5",
                                    marginLeft:"5vw",
                                    marginRight:"5vw"
                                }}/>
                            </MenuItem>
                        </StyledTooltip>
                    </MenuList>
                </Box>
                <Grid container>
                    <div ref = {HomeRef}>
                        <HomePageFragment/>
                    </div>
                    <div ref = {LawRef}>
                        <LawServicesFragment/>
                    </div>
                    <div ref = {CostsRef}>
                        <CostsFragment/>
                    </div>
                    <div ref = {InfoRef}>
                        <InfoFragment/>
                    </div>  
                    <Fab 
                        style={{
                            margin: 0,
                            top: 'auto',
                            right: 20,
                            bottom: 20,
                            position: 'fixed',
                            backgroundColor: "#4267B2",
                            color: '#FFFFFF',
                            borderRadius: 0,
                            width: "65px",
                            height: "65px",
                        }}
                        onClick = {() => 
                            window.open("https://www.facebook.com/profile.php?id=100092370434810", "_blank")
                        }
                    >
                        <FacebookSharpIcon  sx={{fontSize: 35}}/>
                    </Fab> 
                </Grid>
            </ThemeProvider>
        </div>
    )
}

export default MainPage;