import { Route, Routes } from "react-router-dom";
import MainPage from "./MainPage";
import { useEffect, useRef } from "react";


function App() {


  return (
    <div 
    style={{
      display:"flex",
      flexDirection:"column",
      overflowY:"scroll"
    }}>
    <Routes>
      <Route path="/" element={<MainPage/>}/>
      <Route /> 
    </Routes>
    </div>
  );
}

export default App;
