import { Box, Grid, Typography } from "@mui/material";

const Tile = ({TileColor, MiniBoxColor, IdColor, Id, Text, HeightPercent}) => {

    return(
        <div style = {{
            display:"flex",
            justifyContent:"center",
            flexDirection:"column",
            marginLeft:"15px",
            marginRight:"15px",
        }}>
            <Box sx = {{
                width:"250px",
                height:"250px",
                backgroundColor: TileColor,
                transition: "all .2s ease-in-out",
                '&:hover':{
                    opacity: "0.95",
                    transform: "scale(1.1)"
                }
            }}>
                <Box style={{
                    height:"20%",
                    display:"flex",
                    justifyContent:"start",
                    paddingLeft:"15px",
                    paddingTop:"15px",
                }}>
                    <Box style ={{
                        display:"flex",
                        backgroundColor: MiniBoxColor,
                        width:"50px",
                        height:"50px",
                        textAlign:"center",
                        justifyContent:"center",
                    }}>
                        <Typography variant="h4" style = {{                           
                            display:"flex",
                            color: IdColor,
                            fontFamily:"CormorantBold",
                        }}>
                            {Id}
                        </Typography>
                    </Box>
                </Box>
                <Box style={{ 
                    height: HeightPercent, 
                    display:"flex", 
                    flexDirection:"column",
                    textAlign:"center", 
                    alignItems:"center",
                    justifyContent:"center"
                }}>
                    <Typography variant="h5" style = {{                           
                        display:"flex",
                        color: MiniBoxColor,
                        fontFamily:"CormorantBold",
                    }}>
                        {Text}
                    </Typography>
                </Box>
            </Box>
        </div>
    );

}

export default Tile;
