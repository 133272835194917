import { Box, Button, Grid, Typography} from "@mui/material";
import TrendingFlatSharpIcon from '@mui/icons-material/TrendingFlatSharp';
import { Link } from 'react-router-dom';

const IconTile = ({TileColor, MiniBoxColor, IconColor, Icon, Text, HeightPercent, IsButton}) => {

    return(
        <div style = {{
            display:"flex",
            justifyContent:"center",
            flexDirection:"column",
            marginLeft:"2vw",
            marginRight:"2vw",
            marginTop:"3vh",
            marginBottom:"5vh",
        }}>
            <Box sx = {{
                width:"350px",
                height:"350px",
                backgroundColor: TileColor,
            }}>
                <Box sx={{
                    height:"20%",
                    display:"flex",
                    justifyContent:"start",
                    paddingLeft:"15px",
                    paddingTop:"15px",
                }}>
                    <Box sx ={{
                        display:"flex",
                        backgroundColor: MiniBoxColor,
                        width:"60px",
                        height:"60px",
                        alignItems:"center",
                        justifyContent:"center",
                    }}>
                        <div style = {{                           
                            display:"flex",
                            color: IconColor,
                            scale: "1.5",
                        }}>
                            {Icon}
                        </div>
                    </Box>
                </Box>
                <Box style={{ 
                    height: HeightPercent, 
                    display:"flex", 
                    flexDirection:"column",
                    textAlign:"center", 
                    alignItems:"center",
                    justifyContent:"center",
                    whiteSpace:"pre-line",
                }}>
                    <div>
                        <Typography variant="h5" style = {{                           
                            display:"flex",
                            translate: "no",
                            color: MiniBoxColor,
                            display:"flex", 
                            flexDirection:"column",
                            fontFamily:"CormorantBold",
                            paddingBottom:"15px",
                        }}>
                            {Text}
                        </Typography>
                    </div>
                    {IsButton === true ? 
                        (
                            <Button 
                                variant="contained"
                                component = {Link}
                                target = "_blank"
                                to = "https://www.google.com/maps/place/D%C5%82uga+47,+85-034+Bydgoszcz/@53.1208399,17.9990572,17z/data=!3m1!4b1!4m6!3m5!1s0x470313bc8f86b493:0x25f43bb2c6c7891c!8m2!3d53.1208399!4d18.0012459!16s%2Fg%2F11c24b7_ww"
                                sx={{
                                    backgroundColor: MiniBoxColor,
                                    borderRadius:"0",
                                    ':hover' : {
                                        backgroundColor: MiniBoxColor,
                                        opacity: 0.90,
                                    }
                                }}
                            >
                                <Typography sx={{
                                    display:"flex",
                                    color: TileColor,
                                    fontFamily:"CormorantBold",
                                }}>
                                    Zobacz lokalizację na mapie 
                                </Typography>
                            </Button>)
                        : 
                        (<></>)
                    }
                </Box>
            </Box>
        </div>
    );

}

export default IconTile;