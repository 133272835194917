import { Box, Grid, Typography, Fade } from "@mui/material";
import Tile from "./Tile";

const LawServicesFragment = () => {

    const infoArray = [
        { TileColor : "#282828" , MiniBoxColor : "#E7C689", IdColor : "#282828" , Id : "01", Text : "Sporządzanie aktów notarialnych", HeightPercent : "50%"},
        { TileColor : "#8C8C8C" , MiniBoxColor : "#D9D9D9", IdColor : "#282828" , Id : "02", Text : "Sporządzanie aktów poświadczenia dziedziczenia", HeightPercent : "50%"},
        { TileColor : "#8C8C8C" , MiniBoxColor : "#D9D9D9", IdColor : "#282828" , Id : "03", Text : "Podejmowanie czynności dotyczących europejskiego poświadczenia spadkowego", HeightPercent : "70%"},
        { TileColor : "#E7C689" , MiniBoxColor : "#282828", IdColor : "#E7C689" , Id : "04", Text : "Podejmowanie czynności związanych z zarządem sukcesyjnym, przedsiębiorstwem osoby fizycznej", HeightPercent : "70%"},
        { TileColor : "#8C8C8C" , MiniBoxColor : "#D9D9D9", IdColor : "#282828" , Id : "05", Text : "Sporządzanie poświadczeń", HeightPercent : "50%"},
        { TileColor : "#8C8C8C" , MiniBoxColor : "#D9D9D9", IdColor : "#282828" , Id : "06", Text : "Spisywanie protokołów", HeightPercent : "50%"},
        { TileColor : "#282828" , MiniBoxColor : "#E7C689", IdColor : "#282828" , Id : "07", Text : "Przyjmowanie na przechowanie pieniądzy, papierów wartościowych, dokumentów, danych", HeightPercent : "65%"},
        { TileColor : "#8C8C8C" , MiniBoxColor : "#D9D9D9", IdColor : "#282828" , Id : "08", Text : "Sporządzanie protestów, weksli i czeków", HeightPercent : "50%"},
        { TileColor : "#8C8C8C" , MiniBoxColor : "#D9D9D9", IdColor : "#282828" , Id : "09", Text : "Sporządzanie wypisów, odpisów i wyciągów dokumentów", HeightPercent : "50%"},
        { TileColor : "#E7C689" , MiniBoxColor : "#282828", IdColor : "#E7C689" , Id : "10", Text : "Sporządzanie na żądanie stron, projektów aktów, oświadczeń i innych dokumentów", HeightPercent : "65%"},
        { TileColor : "#8C8C8C" , MiniBoxColor : "#D9D9D9", IdColor : "#282828" , Id : "11", Text : "Sporządzanie innych czynności wynikających z odrębnych przepisów", HeightPercent : "50%"},
    ];

    return(
        <div>
            <Box style={{
                display:"flex",
                width:"100vw",
                backgroundColor:"#D9D9D9",
                height:"auto",
                alignItems:"center",
                flexDirection:"column",
            }}>
                <Box style={{
                    width:"100vw",
                    height:"5vh"
                }}>
                    <Typography 
                        variant="h2" 
                        sx={{
                            paddingTop:"5vh",
                            paddingLeft:"3vw",
                            display:"flex",
                            color:"#282828",
                            fontFamily:"CormorantBold",
                            marginBottom:"10vh"
                    }}>
                        Czynności notarialne
                    </Typography>
                </Box>
                <Box style={{
                    display:"flex",
                    flexDirection:"row",
                    overflowX:"scroll",
                    width:"100vw",
                    height:"46vh",
                    marginTop:'10vh',
                    marginBottom: '7vh',
                }}>
                {infoArray.map((infoRow) => (
                    <Tile
                        TileColor={infoRow.TileColor}
                        MiniBoxColor={infoRow.MiniBoxColor}
                        IdColor={infoRow.IdColor}
                        Id={infoRow.Id}
                        Text={infoRow.Text}
                        HeightPercent={infoRow.HeightPercent}
                    />
                ))}
                </Box>
            </Box>
        </div>
    );

}

export default LawServicesFragment;